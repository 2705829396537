<template>
    <b-modal size="md" v-model="isOpen" no-close-on-backdrop no-close-on-esc title="Application Error">
        <div>
            Sorry, but we are unable to process your application at this time. To continue, please call
            <b-link href="tel:888-346-9464">888-346-9464</b-link>
        </div>
        <template #modal-footer="{ ok, cancel }">
            <div class="footer-buttons">
                <Button type="button" text="Ok" cancel @clickEvent="cancel" />
            </div>
        </template>
    </b-modal>
</template>

<script>
import Button from '@/components/FormGroups/Button';

export default {
    name: 'CrimeErrorModal',
    props: {},
    components: { Button },
    data() {
        return {
            isOpen: false
        };
    },
    computed: {},
    methods: {
        cancel() {
            this.isOpen = false;
        },
        show() {
            this.isOpen = true;
        }
    },
    watch: {}
};
</script>

<style scoped lang="scss">
.footer-buttons {
    width: 100%;
    .btn {
        margin-right: 1em;
    }
}
</style>
