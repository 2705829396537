<template>
    <b-form-row>
        <b-col class="text-lead font-weight-light text-capitalize" cols="12">
            2. Who will wear the jewelry items?
        </b-col>
        <b-col v-for="(item, i) in items" :key="i" class="mt-3" cols="12">
            <div class="ml-2">
                <Bullet />
                <b class="font-weight-bold">{{ item.name }}</b>
                <i class="ml-5">
                    valued at
                    <DecimalPrice :price="item.price" />
                </i>
            </div>
            <div class="mt-2 ml-2">
                <RadioButton
                    :model="item.wearerValue"
                    :options="getApplicantAndWearers"
                    :show-error="showErrors"
                    :validator="v => !!v"
                    name="wearers"
                    return-object
                    @inputChanged="val => updateWearer(item, val)"
                />
            </div>
            <div v-if="item.wearer.showForm">
                <JewelryItemNewWearer
                    :model="item.wearer"
                    :show-errors="showErrors"
                    @inputChanged="val => updateNewWearer(item, val)"
                />
            </div>
        </b-col>
    </b-form-row>
</template>

<script>
import { mapGetters } from 'vuex';
import DecimalPrice from '@/components/Common/DecimalPrice';
import RadioButton from '@/components/FormGroups/RadioButton';
import JewelryItemNewWearer from '@/components/ApplicantAndWearer/JewelryItemNewWearer';
import Bullet from '@/components/Common/Bullet';
import constants from '@/constants';
import classes from '@/classes';

export default {
    name: 'WearerJewelryItemsForm',
    components: { Bullet, JewelryItemNewWearer, RadioButton, DecimalPrice },
    props: {
        model: {
            type: Array,
            required: true
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        items: []
    }),
    computed: {
        ...mapGetters(['jewelryItems', 'getApplicantAndWearers']),
        isAllValid() {
            return !this.items.find(item => {
                // Returning true == invalid
                const { wearerValue, wearer } = item;
                if (!wearerValue) {
                    return true;
                }
                if (!wearer.showForm) {
                    return false;
                }
                if (
                    !wearer.firstName ||
                    !wearer.lastName ||
                    !wearer.dateOfBirth ||
                    !wearer.gender ||
                    !wearer.relationshipToApplicant
                ) {
                    return true;
                }
                if (!wearer.isMailingAddress) {
                    const { mailingAddress: address } = wearer;
                    if (!address.address || !address.city || !address.state || !address.zipCode) {
                        return true;
                    }
                }
                return false;
            });
        }
    },
    mounted() {
        this.items = this.jewelryItems.map(({ name, value }, i) => {
            const model = this.model[i] || {};
            const form =
                this.model.length === 0 || this.model.length !== this.jewelryItems.length
                    ? classes.jewelryItemForm
                    : {};

            return {
                ...form,
                ...model,
                name,
                price: value
            };
        });
    },
    watch: {
        items: {
            handler: function(val) {
                this.$emit('inputChanged', val);
            },
            deep: true
        },
        model: {
            deep: true,
            handler: function(val) {
                this.items = val;
            }
        }
    },
    methods: {
        updateWearer(jewelry, wearer) {
            const exists = this.items.filter(item => item.wearer.value === wearer.value).length > 0;

            if (!exists) {
                jewelry.wearer = wearer;
            } else if (
                exists &&
                (wearer.value === constants.wearerApplicantValue || wearer.value === constants.wearerSomeoneElseValue)
            ) {
                jewelry.wearer = wearer;
            } else {
                jewelry.wearer.showForm = false;
            }

            jewelry.wearerValue = wearer.value;
            this.$emit('validate', this.isAllValid);
        },
        updateNewWearer(jewelry, newWearer) {
            const { firstName, lastName } = newWearer;

            if (firstName) {
                newWearer.text = `${firstName} ${lastName || ''}`;
                newWearer.value = `${firstName}_${lastName || ''}`;
            }

            jewelry.wearer = newWearer;
            this.$emit('validate', this.isAllValid);
        }
    }
};
</script>

<style scoped></style>
