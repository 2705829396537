<template>
    <b-form-row>
        <b-col class="text-lead font-weight-light text-capitalize" cols="12">
            3. In the last 7 years, have you or the person wearing the jewelry ever...
        </b-col>
        <b-col class="pl-3 mt-2" cols="12">
            <RadioButton
                :model="model.value"
                :options="items.buttons"
                :show-error="showErrors"
                :validator="v => !!v"
                name="crime-violation"
                title="Been convicted of a crime other than a minor traffic violation?"
                @inputChanged="updateCrimeValue"
            />
        </b-col>
        <CrimeSectionViolation
            v-if="showCrimeViolation"
            :items="items.typeOfConviction"
            :model="model.sentence"
            :show-errors="showErrors"
            class="pl-5"
            @inputChanged="val => (form.sentence = val)"
            @validate="emitValidate"
        />
        <b-col class="pl-3 mt-4" cols="12">
            <b>
                Experienced any loss, theft or damage to your jewelry whether you made an insurance claim or not?
                <i>(This includes any item that you want to insure)</i>
                .
            </b>
            <div class="mt-3">
                <RadioButton
                    :model="model.loss.value"
                    :options="yesNoItems"
                    :show-error="showErrors"
                    :validator="v => !!v"
                    name="loss-theft-damage"
                    @inputChanged="updateLossValue"
                />
            </div>
            <CrimeSectionLostItems
                v-if="showListLostItems"
                :model="model.loss.lostItems"
                :show-errors="showErrors"
                @inputChanged="val => (form.loss.lostItems = val)"
                @validate="emitValidate"
            />
        </b-col>
    </b-form-row>
</template>

<script>
import constants from '@/constants';
import RadioButton from '@/components/FormGroups/RadioButton';
import CrimeSectionViolation from '@/components/ApplicantAndWearer/CrimeSectionViolation';
import CrimeSectionLostItems from '@/components/ApplicantAndWearer/CrimeSectionLostItems';

export default {
    name: 'CrimeSection',
    components: { CrimeSectionLostItems, CrimeSectionViolation, RadioButton },
    props: {
        model: {
            type: Object,
            required: true
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        form: {
            value: '',
            sentence: {
                conviction: '',
                date: ''
            },
            loss: {
                value: '',
                lostItems: []
            }
        },
        items: constants.crimeItems,
        yesNoItems: constants.yesNoItems,
        showCrimeViolation: false,
        showListLostItems: false
    }),
    mounted() {
        this.form = { ...this.model };
    },
    watch: {
        form: {
            deep: true,
            handler: function(val) {
                this.$emit('inputChanged', val);
            }
        }
    },
    methods: {
        isAllValid() {
            if (!this.form || !this.form.loss) {
                return false;
            }
            let valid = !!this.form.value && !!this.form.loss.value;
            if (this.form.value !== 'no') {
                valid = valid && !!this.form.sentence && !!this.form.sentence.conviction && !!this.form.sentence.date;
            }
            if (this.form.loss.value !== 'no') {
                valid =
                    valid &&
                    !!this.form.loss.lostItems &&
                    this.form.loss.lostItems.findIndex(lostItem => !this.isValidLostItem(lostItem)) === -1;
            }
            return valid;
        },
        isValidLostItem(lostItem) {
            return !!lostItem.amount && !!lostItem.date && !!lostItem.type;
        },
        emitValidate() {
            this.$emit('validate', this.isAllValid());
        },
        updateCrimeValue(val) {
            this.showCrimeViolation = val !== this.items.buttons[0].value;
            this.form.value = val;
            this.model.value = val;
            this.emitValidate();
        },
        updateLossValue(val) {
            this.showListLostItems = val === this.yesNoItems[1].value;
            this.form.loss.value = val;
            this.model.loss.value = val;
            this.emitValidate();
        }
    }
};
</script>

<style lang="scss" scoped>
.link-top {
    z-index: 3;
    color: $blue;
}
</style>
