<template>
    <div>
        <ApplicantAndWearersForm v-if="showForm" />
        <ApplicantAndWearersInformation v-else />
    </div>
</template>

<script>
import ApplicantAndWearersInformation from '@/components/ApplicantAndWearer/ApplicantAndWearersInformation';
import ApplicantAndWearersForm from '@/components/ApplicantAndWearer/ApplicantAndWearersForm';
import { mapGetters } from 'vuex';

export default {
    name: 'ApplicantAndWearers',
    components: { ApplicantAndWearersForm, ApplicantAndWearersInformation },
    data: () => ({
        showForm: false
    }),
    created() {
        this.showForm = !!this.applicantAndWearers;

        this.$eventBus.$on('toggle-applicant-and-wearers-form', () => {
            this.showForm = !this.showForm;
        });
    },
    mounted() {
        if (
            !this.jewelryItems ||
            !this.jewelryItems.length ||
            !this.jewelryItems[0].quotes ||
            !this.jewelryItems[0].quotes.length
        ) {
            this.$router.replace('/results');
            return;
        }
        window.scrollTo(0, 0);
    },
    beforeDestroy() {
        this.$eventBus.$off('toggle-applicant-and-wearers-form');
    },
    computed: {
        ...mapGetters(['applicantAndWearers', 'jewelryItems'])
    }
};
</script>
