<template>
    <b-form-group :description="description" :label-for="`form-input-${name}`" class="font-weight-bold" label-size="sm">
        <template v-slot:label>
            {{ title }}
            <template v-if="!!tooltip">
                <b-icon :id="`show-tooltip-${name}`" class="bootstrap-icon" icon="question-circle-fill" />
                <Tooltip :target="`show-tooltip-${name}`" :title="tooltip" />
            </template>
        </template>
        <b-row no-gutters :class="{ 'is-invalid': showError && !isValid }">
            <div class="month">
                <SelectInput
                    :model="selectedMonth"
                    :name="`${name}-month`"
                    :options="months"
                    :validator="v => v !== ''"
                    :show-error="showError"
                    :error-label="null"
                    :autocomplete="autocompletePrefix ? `${autocompletePrefix}month` : null"
                    first="Month"
                    @inputChanged="selectMonth"
                />
            </div>
            <div class="day">
                <SelectInput
                    :model="selectedDay"
                    :name="`${name}-day`"
                    :options="days"
                    :validator="v => !!v"
                    :show-error="showError"
                    :error-label="null"
                    :autocomplete="autocompletePrefix ? `${autocompletePrefix}day` : null"
                    first="Day"
                    @inputChanged="selectDay"
                />
            </div>
            <div class="year">
                <SelectInput
                    :model="selectedYear"
                    :name="`${name}-year`"
                    :options="years"
                    :validator="v => !!v"
                    :show-error="showError"
                    :error-label="null"
                    :autocomplete="autocompletePrefix ? `${autocompletePrefix}year` : null"
                    first="Year"
                    @inputChanged="selectYear"
                />
            </div>
        </b-row>
        <b-form-invalid-feedback v-if="showError && !isValid" class="form-control-error">
            {{ errorLabel }}
        </b-form-invalid-feedback>
    </b-form-group>
</template>

<script>
import moment from 'moment';
import constants from '@/constants';
import SelectInput from '@/components/FormGroups/SelectInput';
import utils from '@/utils';
import Tooltip from '@/components/Common/Tooltip';

export default {
    name: 'DateWithDropdowns',
    components: { Tooltip, SelectInput },
    props: {
        name: {
            type: String,
            required: true
        },
        model: {
            type: [Date, String],
            required: false,
            validator: val => (val ? utils.isDate(val) : true) // If provided, it must be in a date format.
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        tooltip: {
            type: String,
            required: false,
            default: ''
        },
        min: {
            type: [String, Number],
            required: false
        },
        max: {
            type: [String, Number],
            required: false
        },
        autocompletePrefix: {
            type: String,
            required: false
        },
        showMonthNames: {
            type: Boolean,
            required: false,
            default: true
        },
        showError: {
            type: Boolean,
            required: false,
            default: false
        },
        errorLabel: {
            type: String,
            required: false,
            default: 'Please enter a valid date.'
        }
    },
    data: () => ({
        monthsInText: constants.monthNames,
        days: [{ text: '1', value: '1' }],
        selectedDay: '',
        selectedMonth: '',
        selectedYear: ''
    }),
    mounted() {
        if (this.model) {
            const date = new Date(this.model);

            this.selectedDay = date.getDate();
            this.selectedMonth = date.getMonth();
            this.selectedYear = date.getFullYear();
        }

        this.updateDays();
    },
    computed: {
        minDate() {
            return this.min
                ? new Date(this.min)
                : moment()
                      .add(10, 'years')
                      .toDate();
        },
        maxDate() {
            return this.max
                ? new Date(this.max)
                : moment()
                      .add(10, 'years')
                      .toDate();
        },
        calculatedDate() {
            if (this.isValid) {
                return new Date(this.selectedYear, this.selectedMonth, this.selectedDay);
            }
            return null;
        },
        months() {
            let months = [];
            let maxMonth = 12;
            if (this.maxDate.getFullYear() === this.selectedYear) {
                maxMonth = this.maxDate.getMonth() + 1;
            }

            for (let i = 0; i < maxMonth; i++) {
                if (this.showMonthNames) {
                    const { monthNames } = constants;

                    months.push(monthNames[i]);
                } else {
                    months.push(i + 1);
                }
            }

            return months.map((month, i) => ({
                text: month.toString(),
                value: i
            }));
        },
        years() {
            let years = [];
            const max = this.max ? this.maxDate.getFullYear() : new Date().getFullYear();
            const min = this.min ? this.minDate.getFullYear() : max - 9;

            for (let i = max; i >= min; i--) {
                years.push(i);
            }

            return years.map(year => {
                const yearString = year.toString();

                return {
                    text: yearString,
                    value: yearString
                };
            });
        },
        isValid() {
            return this.selectedMonth !== '' && !!this.selectedDay && !!this.selectedYear;
        }
    },
    methods: {
        getDays() {
            const days = [];
            let daysNumberOfMonth = 31;
            if (this.selectedMonth) {
                daysNumberOfMonth = new Date(this.selectedYear || 2020, Number(this.selectedMonth) + 1, 0).getDate();
            }

            if (
                this.selectedYear &&
                this.maxDate.getFullYear() === Number(this.selectedYear) &&
                this.maxDate.getMonth() === this.selectedMonth
            ) {
                daysNumberOfMonth = this.maxDate.getDate();
            }

            for (let i = 1; i < daysNumberOfMonth + 1; i++) {
                days.push(i);
            }

            if (this.selectedDay && daysNumberOfMonth < Number(this.selectedDay)) {
                this.selectedDay = daysNumberOfMonth;
            }

            return days.map(day => ({
                text: day.toString(),
                value: day.toString()
            }));
        },
        updateDays() {
            const newDays = this.getDays();
            if (newDays.length !== this.days.length) {
                this.days = newDays;
            }
        },
        sendDate() {
            this.$emit('inputChanged', this.calculatedDate);
            this.$emit('validate', this.isValid);
        },
        selectDay(val) {
            this.selectedDay = val;
            this.sendDate();
        },
        selectMonth(val) {
            this.selectedMonth = val;
            this.updateDays();
            this.sendDate();
        },
        selectYear(val) {
            this.selectedYear = val;
            this.updateDays();
            this.sendDate();
        }
    }
};
</script>

<style scoped>
.month,
.year,
.day {
    padding-left: 2px;
    padding-right: 2px;
    margin-top: -15px;
}

.month {
    max-width: 120px;
    min-width: 70px;
    width: 40%;
}

.day {
    max-width: 80px;
    min-width: 50px;
    width: 25%;
}

.year {
    max-width: 100px;
    min-width: 80px;
    width: 35%;
}
</style>
