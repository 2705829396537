<template>
    <b-col cols="12">
        <b-row no-gutters class="align-items-center">
            <b-col cols="6">
                <b>Sentence completion date</b>
            </b-col>
            <b-col cols="6">
                <TextInput
                    :model="model.date"
                    :show-error="showErrors"
                    :validator="required"
                    name="sentence-completion-date"
                    type="date"
                    @inputChanged="val => (model.date = val)"
                    @validate="v => setValid('sentence', v)"
                />
            </b-col>
        </b-row>
        <b-row no-gutters class="align-items-center">
            <b-col cols="6">
                <b>Type of conviction</b>
            </b-col>
            <b-col cols="6">
                <SelectInput
                    :model="model.conviction"
                    :options="items"
                    :show-error="showErrors"
                    :validator="required"
                    name="type-of-conviction"
                    @inputChanged="val => (model.conviction = val)"
                    @validate="v => setValid('sentence', v)"
                    return-object
                />
            </b-col>
        </b-row>
    </b-col>
</template>

<script>
import TextInput from '@/components/FormGroups/TextInput';
import SelectInput from '@/components/FormGroups/SelectInput';

export default {
    name: 'CrimeSectionViolation',
    components: { SelectInput, TextInput },
    props: {
        items: {
            type: Array,
            required: true
        },
        model: {
            type: Object,
            required: true
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        valid: {
            sentence: false,
            conviction: false
        }
    }),
    computed: {
        isAllValid() {
            return !Object.keys(this.valid).find(f => !this.valid[f]);
        }
    },
    methods: {
        required(val) {
            return !!val;
        },
        setValid(field, value) {
            this.valid[field] = value;
            this.$emit('validate', this.isAllValid);
        }
    }
};
</script>

<style scoped></style>
