<template>
    <div class="center-mobile">
        <Title heading="What You'll Need" />
        <b-row class="mb-5" no-gutters>
            <b-col cols="12" lg="2" md="2" sm="3">
                <b-img
                    :src="require('@/assets/applicantAndWearers/wearer-illustration.svg')"
                    alt="wearer-illustration"
                    width="80"
                />
            </b-col>
            <b-col class="pt-2" cols="12" lg="10" md="10" sm="9">
                <span class="small-mobile">If someone else wears the jewelry:</span>
                <p class="small-mobile text-capitalize font-weight-bold">
                    Wearer's name & address
                </p>
            </b-col>
        </b-row>

        <b-row class="mb-5" no-gutters>
            <b-col cols="12" lg="2" md="2" sm="3">
                <b-img
                    :src="require('@/assets/applicantAndWearers/alarm-system-illustration.svg')"
                    alt="alarm-system-illustration"
                    width="80"
                />
            </b-col>
            <b-col class="pt-2" cols="12" lg="10" md="10" sm="9">
                <span class="small-mobile">If the wearer has an alarm system:</span>
                <p class="small-mobile text-capitalize font-weight-bold">
                    Alarm system details
                </p>
            </b-col>
        </b-row>

        <b-row class="mb-5" no-gutters>
            <b-col cols="12" lg="2" md="2" sm="3">
                <b-img
                    :src="require('@/assets/applicantAndWearers/documentation-illustration.svg')"
                    alt="documentation"
                    width="80"
                />
            </b-col>
            <b-col class="small-mobile pt-2" cols="12" lg="10" md="10" sm="9">
                <span class="font-weight-bold">Documentation</span>
                <p>
                    To apply, you only need the appraised value (if it's been appraised) or full purchase price of your
                    item(s).
                </p>
                <p>
                    It's highly recommended that you upload an appraisal or detailed receipt, to ensure exact repair or
                    replacement in the event of a claim.
                </p>
                <p>
                    <b>Don't have documentation on you?</b>
                    Apply now, upload later. That way your coverage can start ASAP.
                </p>
            </b-col>
        </b-row>
        <b-row class="mt-2" no-gutters>
            <b-col class="small-mobile" cols="12">
                In order to protect your privacy, this application will time out after 30 minutes of inactivity.
            </b-col>
            <b-col class="mt-4" cols="12">
                <Button text="Start application" @clickEvent="startApplication" />
            </b-col>
        </b-row>

        <b-row class="mt-5 w-100" no-gutters>
            <span class="back" @click="back">&lt;&nbsp;Back</span>
        </b-row>
    </div>
</template>

<script>
import Title from '@/components/Common/Title';
import Button from '@/components/FormGroups/Button';

export default {
    name: 'ApplicantAndWearersInformation',
    components: { Title, Button },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        startApplication() {
            this.$eventBus.$emit('toggle-applicant-and-wearers-form');
        },
        back() {
            this.$router.push('/results');
        }
    }
};
</script>

<style lang="scss" scoped>
.small-mobile {
    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.back {
    color: $blue;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
}
</style>
