<template>
    <b-form-row v-if="form">
        <h6 class="font-weight-bold my-3 form-padding">
            Please provide information for the wearer of this jewelry item.
        </h6>
        <b-col class="form-padding" cols="12" sm="6">
            <TextInput
                :model="model.firstName"
                :show-error="showErrors"
                :validator="required"
                name="new-wearer-firstname"
                title="First Name"
                @inputChanged="val => (form.firstName = val)"
            />
        </b-col>
        <b-col class="form-padding" cols="12" sm="6">
            <TextInput
                :model="model.lastName"
                :show-error="showErrors"
                :validator="required"
                name="new-wearer-lastname"
                title="Last Name"
                @inputChanged="val => (form.lastName = val)"
            />
        </b-col>
        <b-col class="form-padding" cols="12">
            <Checkbox
                :model="model.isMailingAddress"
                :show-error="showErrors"
                :validator="required"
                name="new-wearer-is-mailing-address"
                title="Address same as primary applicant"
                @inputChanged="val => (form.isMailingAddress = val)"
            />
            <MailingAddressForm
                v-if="!model.isMailingAddress"
                :form="model.mailingAddress"
                no-prefix
                @inputChanged="val => (form.mailingAddress = val)"
            />
        </b-col>
        <b-col class="form-padding" cols="12" sm="6">
            <formatted-input type="phone" label="Phone Number (optional)" v-model="model.phoneNumber"></formatted-input>
        </b-col>
        <b-col class="form-padding" cols="12" sm="6">
            <TextInput
                :model="model.emailAddress"
                name="new-wearer-email-address"
                title="Email Address (optional)"
                @inputChanged="val => (form.emailAddress = val)"
            />
        </b-col>
        <b-col class="form-padding" cols="12" lg="6" md="12">
            <DateWithDropdowns
                :model="model.dateOfBirth"
                :show-error="showErrors"
                :validator="required"
                name="new-wearer-date-of-birth"
                title="Date of Birth"
                min="1901-01-01"
                @inputChanged="val => (form.dateOfBirth = val)"
            />
        </b-col>
        <b-col class="form-padding" cols="12" lg="6" md="12">
            <SelectInput
                :model="model.relationshipToApplicant"
                :options="relationshipsToApplicant"
                :show-error="showErrors"
                :validator="required"
                name="new-wearer-relationship-to-applicant"
                title="Relationship to Applicant"
                @inputChanged="val => (form.relationshipToApplicant = val)"
            />
        </b-col>
        <b-col class="form-padding" cols="12">
            <RadioButton
                :model="model.gender"
                :options="genders"
                :show-error="showErrors"
                :validator="required"
                name="new-wearer-gender"
                @inputChanged="val => (form.gender = val)"
            />
        </b-col>
    </b-form-row>
</template>

<script>
import TextInput from '@/components/FormGroups/TextInput';
import Checkbox from '@/components/FormGroups/Checkbox';
import MailingAddressForm from '@/components/MailingAddressForm';
import DateWithDropdowns from '@/components/FormGroups/DateWithDropdowns';
import constants from '@/constants';
import SelectInput from '@/components/FormGroups/SelectInput';
import RadioButton from '@/components/FormGroups/RadioButton';
import FormattedInput from '@/components/FormGroups/FormattedInput';

export default {
    name: 'JewelryItemNewWearer',
    components: {
        FormattedInput,
        RadioButton,
        SelectInput,
        DateWithDropdowns,
        MailingAddressForm,
        Checkbox,
        TextInput
    },
    props: {
        model: {
            type: Object,
            required: true
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        form: null,
        genders: constants.genders,
        relationshipsToApplicant: constants.relationshipsToApplicant
    }),
    mounted() {
        this.form = { ...this.model };
    },
    methods: {
        required(val) {
            return !!val;
        }
    },
    watch: {
        form: {
            deep: true,
            handler: function(val) {
                this.$emit('inputChanged', val);
            }
        }
    }
};
</script>

<style scoped></style>
