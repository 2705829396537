<template>
    <div class="addressVerificationModalWrapper">
        <b-modal
            id="addressVerificationModal"
            size="md"
            v-model="isOpen"
            hide-footer
            no-close-on-backdrop
            no-close-on-esc
        >
            <template v-slot:modal-title>
                <h3>{{ title }}</h3>
            </template>
            <div v-if="isOpen">
                <div v-if="loading">
                    <div class="loading-spinner-container mb-5">
                        <img class="loading-spinner" alt="spinner" :src="require('@/assets/spinner-black.png')" />
                    </div>
                    <div class="d-flex justify-content-center align-items-center mb-5">
                        <div class="info-message">Please wait while we verify your address.</div>
                    </div>
                </div>
                <div class="ml-3 mr-2 mb-3" v-else>
                    <div class="info-message mb-3">
                        We weren't able to verify your address by comparing against a national database. If this is your
                        correct address, please give us a call at 888-884-2424 so that we can assist you with your
                        application.
                    </div>
                    <div class="info-message mb-4">We apologize for any inconvenience.</div>

                    <div v-if="displayAdditionalApt">
                        <div class="info-message">Please enter an apartment, suite, or unit.</div>
                        <div class="d-flex justify-content-start align-items-center">
                            <div class="">
                                <b-form-input v-model.trim="additionalAddress2"></b-form-input>
                            </div>

                            <div class="ml-4">
                                <Button
                                    class="confirmAddress-button"
                                    text="Confirm"
                                    type="button"
                                    @clickEvent="confirmAddress"
                                />
                            </div>
                        </div>
                        <div v-if="currentItem.errorAddressNotComplete">
                            <p class="error-message mb-2">
                                We weren't able to verify your address by comparing against a national database. If this
                                is your correct address, please give us a call at 888-884-2424 so that we can assist you
                                with your application.
                            </p>
                            <p class="error-message mb-2 ">
                                We apologize for any inconvience.
                            </p>
                        </div>
                    </div>

                    <div class="mb-2">
                        <div class="info-message mb-2">You Entered:</div>
                        <div class="ml-2">
                            <div>{{ currentItem.originalAddress.address1 }}</div>
                            <div>{{ currentItem.originalAddress.address2 }}</div>
                            <div>
                                {{ currentItem.originalAddress.city }}, {{ currentItem.originalAddress.state }}
                                {{ currentItem.originalAddress.zip }}
                            </div>
                        </div>
                    </div>

                    <Button text="Edit Your Entry " type="button" @clickEvent="editEntry" />

                    <div class="mt-5 mr-2" v-if="displayMatches">
                        <div class="info-message">Potential matches:</div>
                        <div class="d-flex justify-content-between align-items-center potential-matches">
                            <div>
                                {{ currentItem.address1 }} {{ currentItem.address2 }}, {{ currentItem.city }}
                                {{ currentItem.state }}
                                {{ currentItem.zip }}
                            </div>
                            <div><Button text="Use this address " type="button" @clickEvent="useThisAddress" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';
import assign from 'lodash/assign';

import Button from '@/components/FormGroups/Button';
import axios from 'axios';
import { mapMutations, mapGetters } from 'vuex';

export default {
    name: 'AddressVerificationModal',
    props: {
        form: {
            type: Object
        }
    },
    components: { Button },
    data() {
        return {
            isOpen: false,
            listToVerify: [],
            currentItemIndex: 0,
            currentItem: {},
            additionalAddress2: '',
            loading: true,
            zipCodeHasChanged: false
        };
    },
    computed: {
        ...mapGetters(['previewQuoteLocation']),
        title() {
            return this.loading ? 'Verifying Your Addresses' : 'Verify Your Address';
        },
        complete() {
            return get(this.currentItem, 'complete', undefined);
        },
        updated() {
            return get(this.currentItem, 'updated', false);
        },
        found() {
            return get(this.currentItem, 'found', false);
        },
        isError() {
            return get(this.currentItem, 'errorAddressNotComplete', false);
        },
        displayMatches() {
            return this.found && this.updated && this.complete;
        },
        displayAdditionalApt() {
            return (this.found && !this.complete) || this.errorAddressNotComplete;
        }
    },
    methods: {
        ...mapMutations(['setPreviewQuoteLocation']),
        getAddressToVerify() {
            const addressToVerify = [];
            const applicantAddress = {
                address1: this.form.applicant.address,
                address2: this.form.applicant.apartmentSuite,
                city: this.form.applicant.city,
                country: this.form.applicant.country,
                state: this.form.applicant.state,
                zip: this.form.applicant.zipCode,
                inputId: 'applicantAddress'
            };
            addressToVerify.push(applicantAddress);
            if (!this.form.applicant.isMailingAddress) {
                //if mailing address not the same as applicant address,  verify that address as well
                const mailingAddress = {
                    address1: this.form.applicant.mailingAddress.address,
                    address2: this.form.applicant.mailingAddress.apartmentSuite,
                    city: this.form.applicant.mailingAddress.city,
                    state: this.form.applicant.mailingAddress.state,
                    zip: this.form.applicant.mailingAddress.zipCode,
                    country: this.form.applicant.country,
                    inputId: 'applicantMailingAddress'
                };
                addressToVerify.push(mailingAddress);
            }
            for (let i = 0; i < this.form.wearerJewelryItems.length; i++) {
                const address = get(this.form.wearerJewelryItems[i], 'wearer.mailingAddress', null);
                const isSameAsApplicantAddress = get(this.form.wearerJewelryItems[i], 'wearer.isMailingAddress', true);
                if (address && !isSameAsApplicantAddress) {
                    const wearerAddress = {
                        address1: address.address,
                        address2: address.apartmentSuite,
                        city: address.city,
                        country: this.form.applicant.country,
                        state: address.state,
                        zip: address.zipCode,
                        inputId: 'wearerAddress-' + i
                    };
                    addressToVerify.push(wearerAddress);
                }
            }
            return addressToVerify || [];
        },
        async verifyAddress() {
            this.loading = true;
            const addressToVerify = this.getAddressToVerify();

            try {
                const { data } = await axios.post(`/api/address/verifyAddresses`, addressToVerify);
                this.processAddress(addressToVerify, data);
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.reset();
            }
        },
        reset() {
            this.listToVerify = [];
            this.currentItemIndex = 0;
            this.currentItem = {};
            this.additionalAddress2 = '';
            //    this.zipCodeHasChanged = false;
        },
        processAddress(userAddress, verifiedAddress) {
            const that = this;
            const userAddressMap = keyBy(userAddress, 'inputId');
            const listToVerify = [];
            forEach(verifiedAddress, function(address) {
                if (address.found && address.complete) {
                    // found perfect address do nothing just skip it.

                    that.updateAddress(address); //will use what ever came back
                    // if (address.inputId === 'applicantAddress') {
                    //     that.updateLocation(address);
                    // }
                } else {
                    address.originalAddress = userAddressMap[address.inputId];
                    listToVerify.push(address);
                }
            });
            this.listToVerify = listToVerify;
            this.goNext();
        },
        editEntry() {
            this.isOpen = false;
            this.reset();
        },
        useThisAddress() {
            this.updateAddress(this.currentItem);
            this.goNext();
        },
        updateLocation(address) {
            //zipCodeHasChanged will get reset after it emit the event with it
            this.zipCodeHasChanged = this.zipCodeHasChanged || address.zip !== this.previewQuoteLocation.zipCode;
            const d = {
                zipCode: address.zip,
                city: address.city,
                county: address.county,
                state: address.state,
                country: address.country,
                streetName: address.address1 + ', ' + address.address2
            };
            this.setPreviewQuoteLocation(d);
        },
        updateAddress(currentItem) {
            const inputId = currentItem.inputId;

            let streetName = currentItem.address1.trim();
            if (currentItem.address2) {
                streetName = streetName + ', ' + currentItem.address2;
            }
            if (inputId === 'applicantAddress') {
                this.form.applicant.address = currentItem.address1;
                this.form.applicant.apartmentSuite = currentItem.address2;
                this.form.applicant.city = currentItem.city;
                this.form.applicant.state = currentItem.state;
                this.form.applicant.zipCode = currentItem.zip;
                this.form.applicant.country = currentItem.country;
                this.form.applicant.county = currentItem.county;
                this.form.applicant.streetName = streetName.trim();
                this.updateLocation(currentItem);
            } else if (inputId === 'applicantMailingAddress') {
                this.form.applicant.mailingAddress.address = currentItem.address1;
                this.form.applicant.mailingAddress.apartmentSuite = currentItem.address2;
                this.form.applicant.mailingAddress.city = currentItem.city;
                this.form.applicant.mailingAddress.state = currentItem.state;
                this.form.applicant.mailingAddress.zipCode = currentItem.zip;
                this.form.applicant.mailingAddress.country = currentItem.country;
                this.form.applicant.mailingAddress.county = currentItem.county;
                this.form.applicant.mailingAddress.streetName = streetName.trim();
            } else if (inputId.includes('wearerAddress')) {
                const index = Number(inputId.replace('wearerAddress-', '') || 0);
                this.form.wearerJewelryItems[index].wearer.mailingAddress.address = currentItem.address1;
                this.form.wearerJewelryItems[index].wearer.mailingAddress.apartmentSuite = currentItem.address2;
                this.form.wearerJewelryItems[index].wearer.mailingAddress.city = currentItem.city;
                this.form.wearerJewelryItems[index].wearer.mailingAddress.state = currentItem.state;
                this.form.wearerJewelryItems[index].wearer.mailingAddress.zipCode = currentItem.zip;
                this.form.wearerJewelryItems[index].wearer.mailingAddress.country = currentItem.country;
                this.form.wearerJewelryItems[index].wearer.mailingAddress.county = currentItem.county;
                this.form.wearerJewelryItems[index].wearer.mailingAddress.streetName = streetName.trim();
            }
        },
        goNext() {
            if (this.currentItemIndex < this.listToVerify.length) {
                this.currentItem = this.listToVerify[this.currentItemIndex++];
            } else {
                //all done need to proceed.
                this.isOpen = false;
                this.$emit('finished', this.zipCodeHasChanged);
                this.zipCodeHasChanged = false;
                this.reset();
            }
        },
        async confirmAddress() {
            const verifyAddressAgain = [
                {
                    address1: this.currentItem.originalAddress.address1,
                    address2: this.additionalAddress2,
                    city: this.currentItem.originalAddress.city,
                    country: this.currentItem.originalAddress.country,
                    state: this.currentItem.originalAddress.state,
                    zip: this.currentItem.originalAddress.zipCode,
                    inputId: this.currentItem.inputId
                }
            ];

            try {
                this.loading = true;
                this.currentItem.errorAddressNotComplete = false;
                const { data } = await axios.post(`/api/address/verifyAddresses`, verifyAddressAgain);
                const verifiedAddress = data[0];

                assign(this.listToVerify[this.currentItemIndex - 1], verifiedAddress);
                this.currentItem = this.listToVerify[this.currentItemIndex - 1];
                if (verifiedAddress.found && verifiedAddress.complete) {
                    this.updateAddress(this.currentItem);
                    this.goNext();
                } else if (verifiedAddress.found) {
                    this.currentItem.originalAddress.address2 = verifiedAddress.address2;
                    // not complete address does not match any building display error
                    this.currentItem.errorAddressNotComplete = true;
                } else {
                    // not complete address does not match any building display error
                    this.currentItem.errorAddressNotComplete = true;
                }

                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.reset();
            }
        }
    },
    watch: {
        isOpen(isModalOpen) {
            if (isModalOpen) {
                this.reset();
                this.verifyAddress();
            }
        }
    }
};
</script>

<style scoped lang="scss">
/deep/ .modal-dialog {
    min-width: 600px !important;
}
.potential-matches {
    margin-top: -10px;
}
.confirmAddress-button {
    min-width: 0;
}
.info-message {
    font-weight: 700;
    font-size: 14px;
    color: black;
}
.error-message {
    font-size: 12px;
    color: red;
}
.loading-spinner-container {
    display: flex;
    justify-content: center;
    margin-top: 1em;
}
.loading-spinner {
    animation: spin 1000ms infinite linear;
    height: 50px;
    width: 50px;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
