<template>
    <b-form-checkbox v-model="value" :state="fieldState">
        {{ title }}
    </b-form-checkbox>
</template>

<script>
export default {
    name: 'Checkbox',
    props: {
        name: {
            type: String,
            required: true
        },
        model: {
            type: Boolean,
            required: true,
            default: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        tooltip: {
            type: String,
            required: false,
            default: ''
        },
        validator: {
            type: Function,
            required: false,
            default: () => true
        },
        showError: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        value: ''
    }),
    computed: {
        isValid() {
            return !!this.validator(this.value);
        },
        displayError() {
            return this.userBlurred || this.showError;
        },
        fieldState() {
            return !this.displayError || this.isValid ? null : false;
        }
    },
    mounted() {
        this.value = this.model;
    },
    watch: {
        value(val) {
            this.$emit('inputChanged', val);
            this.$emit('validate', this.isValid);
        },
        model(val) {
            this.value = val;
        }
    }
};
</script>

<style lang="scss" scoped>
/deep/ .custom-control-label {
    cursor: pointer;

    span {
        // Between bullet and text is not covered by bootstrap's radio button component.
        // If a user clicks there, nothing will happen.
        // To prevent that, we pull the text close to the bullet and apply padding to push away visually
        // so the empty area can be clickable.
        margin-left: -7px;
        padding-left: 7px;
    }
}

/deep/ .custom-control-input:checked ~ .custom-control-label:after {
    border: 0;
    background-color: $pacific-blue;
}

/deep/ .custom-control-label:after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 2px;
}

/deep/ .custom-control-input:not(:checked):hover ~ .custom-control-label:after {
    background-color: grey;
    border-radius: 50%;
    transition: 0.5s background-color ease;
    cursor: pointer;
}
</style>
