<template>
    <b-form-row>
        <b-col class="text-lead font-weight-light text-capitalize" cols="12">
            4. Important information about our website and your privacy:
        </b-col>
        <div class="pl-3 mt-2" :class="{ 'is-invalid': showErrors && !isValid }">
            Please review the Jewelers Mutual website
            <b-link class="link-top" href="https://www.jewelersmutual.com/terms-of-use" target="_blank">
                Terms Of Use
            </b-link>
            and
            <b-link class="link-top" href="https://www.jewelersmutual.com/privacy-policy" target="_blank">
                Privacy Policy
            </b-link>
            . In addition, to learn more about how Jewelers Mutual protects its customers' nonpublic personal
            information, including financial information, please read our "Notice to Policyholders: How We Protect Your
            Privacy" (
            <b-link class="link-top" href="/privacy-notice" target="_blank">Privacy Notice</b-link>
            ).
        </div>
        <div class="mt-3 d-flex pl-3" :class="{ 'is-invalid': showErrors && !isValid }">
            <Checkbox
                :model="model"
                :show-error="showErrors"
                :validator="v => !!v"
                name="terms-of-conditions"
                @inputChanged="val => $emit('inputChanged', val)"
                @validate="v => $emit('validate', v)"
            />
            <span @click="$emit('inputChanged', !model)">
                I agree to the website
                <b-link class="link-top" href="https://www.jewelersmutual.com/terms-of-use" target="_blank">
                    Terms Of Use
                </b-link>
                and
                <b-link class="link-top" href="https://www.jewelersmutual.com/privacy-policy" target="_blank">
                    Privacy Policy
                </b-link>
                , and I have read the
                <b-link class="link-top" href="privacy-notice" target="_blank">Privacy Notice</b-link>
                .
            </span>
        </div>
    </b-form-row>
</template>

<script>
import Checkbox from '@/components/FormGroups/Checkbox';

export default {
    name: 'ImportantInformationAboutWebsite',
    components: { Checkbox },
    props: {
        model: {
            type: Boolean,
            required: true
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        isValid() {
            return !!this.model;
        }
    }
};
</script>

<style scoped lang="scss">
.link-top {
    z-index: 3;
    color: $blue;
}
.is-invalid {
    color: red;
}
</style>
