<template>
    <div>
        <Title heading="Applicant & Wearer Information" />
        <validation-observer ref="form" tag="div">
            <b-form v-if="applicantAndWearersForm" @submit.prevent="submit" class="applicant-wearers-form">
                <div v-if="validatingAddresses" class="validation-overlay">
                    Validating address
                </div>
                <Panel>
                    <b-form-row>
                        <b-col class="text-lead font-weight-light text-capitalize" cols="12">
                            1. Your contact information
                        </b-col>
                        <b-col cols="12" sm="6">
                            <TextInput
                                :model="applicantAndWearersForm.applicant.firstName"
                                :show-error="showAllErrors"
                                :validator="required"
                                name="applicant-firstname"
                                autocomplete="given-name"
                                title="First Name"
                                @inputChanged="val => (form.applicant.firstName = val)"
                                @validate="v => setValid('firstName', v)"
                            />
                        </b-col>
                        <b-col cols="12" sm="6">
                            <TextInput
                                :model="applicantAndWearersForm.applicant.lastName"
                                :show-error="showAllErrors"
                                :validator="required"
                                name="applicant-lastname"
                                autocomplete="family-name"
                                title="Last Name"
                                @inputChanged="val => (form.applicant.lastName = val)"
                                @validate="v => setValid('lastName', v)"
                            />
                        </b-col>
                        <b-col cols="12" sm="6">
                            <TextInput
                                :model="applicantAndWearersForm.applicant.address"
                                :show-error="showAllErrors"
                                :validator="required"
                                name="applicant-address"
                                autocomplete="billing address-line1"
                                title="Address"
                                @inputChanged="val => (form.applicant.address = val)"
                                @validate="v => setValid('address', v)"
                            />
                        </b-col>
                        <b-col cols="12" sm="6">
                            <TextInput
                                :model="applicantAndWearersForm.applicant.apartmentSuite"
                                name="applicant-apartment-suite"
                                autocomplete="billing address-line2"
                                title="Apartment/Suite"
                                @inputChanged="val => (form.applicant.apartmentSuite = val)"
                            />
                        </b-col>
                        <b-col cols="12" sm="6">
                            <TextInput
                                :model="applicantAndWearersForm.applicant.city"
                                :validator="required"
                                :show-error="showAllErrors"
                                name="applicant-city"
                                autocomplete="billing address-level2 locality"
                                title="City"
                                @inputChanged="val => (form.applicant.city = val)"
                                @validate="v => setValid('city', v)"
                            />
                        </b-col>
                        <b-col cols="12" sm="6">
                            <SelectInput
                                :model="applicantAndWearersForm.applicant.state"
                                :show-error="showAllErrors"
                                :validator="required"
                                :options="stateOptions"
                                name="applicant-state"
                                autocomplete="billing address-level1 province"
                                :title="country === 'US' ? 'State' : 'Province'"
                                @inputChanged="val => (form.applicant.state = val)"
                                @validate="v => setValid('state', v)"
                            />
                        </b-col>
                        <b-col cols="12">
                            <b-col class="pa-0" cols="5" sm="3">
                                <TextInput
                                    :model="applicantAndWearersForm.applicant.zipCode"
                                    :show-error="showAllErrors"
                                    :validator="isValidZipCode"
                                    name="applicant-zip-code"
                                    autocomplete="billing postal-code"
                                    title="Zip Code"
                                    @inputChanged="val => (form.applicant.zipCode = val)"
                                    @validate="v => setValid('zipCode', v)"
                                />
                            </b-col>
                            <b-col class="pa-0" cols="12">
                                <Checkbox
                                    :model="applicantAndWearersForm.applicant.isMailingAddress"
                                    name="mailing-address"
                                    title="This is my mailing address"
                                    @inputChanged="showMailingAddress"
                                />
                                <MailingAddressForm
                                    v-if="!applicantAndWearersForm.applicant.isMailingAddress"
                                    :form="applicantAndWearersForm.applicant.mailingAddress"
                                    :show-errors="showAllErrors"
                                    @inputChanged="val => (form.applicant.mailingAddress = val)"
                                    @validate="v => setValid('mailingAddress', v)"
                                />
                            </b-col>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <validation-provider rules="required" v-slot="{ errors }">
                                <formatted-input
                                    type="phone"
                                    label="Phone Number"
                                    v-model="applicantAndWearersForm.applicant.phoneNumber"
                                    :error-message="errors[0]"
                                ></formatted-input>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <TextInput
                                :model="applicantAndWearersForm.applicant.emailAddress"
                                :show-error="showAllErrors"
                                :validator="required"
                                name="applicant-email-address"
                                autocomplete="email"
                                title="Email Address"
                                type="email"
                                @inputChanged="val => (form.applicant.emailAddress = val)"
                                @validate="v => setValid('emailAddress', v)"
                            />
                        </b-col>
                        <b-col cols="12">
                            <DateWithDropdowns
                                :model="applicantAndWearersForm.applicant.dateOfBirth"
                                :show-error="showAllErrors"
                                name="dateOfBirth"
                                autocomplete-prefix="bday-"
                                title="Date of Birth"
                                @inputChanged="val => (form.applicant.dateOfBirth = val)"
                                min="1901-01-01"
                                :max="youngestApplicantAge"
                                tooltip="We use this to verify your identity, including to ensure you’re over 18."
                                @validate="v => setValid('dateOfBirth', v)"
                            />
                        </b-col>
                        <b-col cols="12">
                            <RadioButton
                                :model="applicantAndWearersForm.applicant.gender"
                                :options="genders"
                                :show-error="showAllErrors"
                                :validator="required"
                                error-label="Gender is required."
                                name="gender"
                                autocomplete-prefix="sex"
                                @inputChanged="val => (form.applicant.gender = val)"
                                @validate="v => setValid('gender', v)"
                            />
                        </b-col>
                    </b-form-row>
                </Panel>
                <Panel>
                    <WearerJewelryItemsForm
                        :model="applicantAndWearersForm.wearerJewelryItems"
                        :show-errors="showAllErrors"
                        @inputChanged="val => (form.wearerJewelryItems = val)"
                        @validate="v => setValid('wearers', v)"
                    />
                </Panel>
                <Panel>
                    <CrimeSection
                        :model="applicantAndWearersForm.wearerCrime"
                        :show-errors="showAllErrors"
                        @inputChanged="val => (form.wearerCrime = val)"
                        @validate="v => setValid('crime', v)"
                    />
                </Panel>
                <Panel>
                    <ImportantInformationAboutWebsite
                        :model="applicantAndWearersForm.agreedTerms"
                        :show-errors="showAllErrors"
                        @inputChanged="val => (form.agreedTerms = val)"
                        @validate="v => setValid('termsOfService', v)"
                    />
                </Panel>
                <b-row no-gutters>
                    <b-col class="mobile-reverse" cols="12">
                        <div>
                            <span class="save" @click="saveAndFinishLater">Save & Finish Later</span>
                        </div>
                        <div>
                            <Button text="Next" type="submit" />
                        </div>
                    </b-col>
                    <b-col cols="12">
                        <div><span class="back" @click="back">&lt;&nbsp;Back</span></div>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <address-verification-modal :form="form" @finished="finishedAddressValidation"></address-verification-modal>
        <crime-error-modal ref="crimeModal"></crime-error-modal>
    </div>
</template>

<script>
import Title from '@/components/Common/Title';
import Panel from '@/components/Common/Panel';
import TextInput from '@/components/FormGroups/TextInput';
import Button from '@/components/FormGroups/Button';
import constants from '@/constants';
import SelectInput from '@/components/FormGroups/SelectInput';
import Checkbox from '@/components/FormGroups/Checkbox';
import DateWithDropdowns from '@/components/FormGroups/DateWithDropdowns';
import RadioButton from '@/components/FormGroups/RadioButton';
import MailingAddressForm from '@/components/MailingAddressForm';
import WearerJewelryItemsForm from '@/components/ApplicantAndWearer/WearerJewelryItemsForm';
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import ImportantInformationAboutWebsite from '@/components/Quote/ImportantInformationAboutWebsite';
import CrimeSection from '@/components/ApplicantAndWearer/CrimeSection';
import cloneDeep from 'lodash/cloneDeep';
import classes from '@/classes';
import FormattedInput from '@/components/FormGroups/FormattedInput';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { scrollToError } from '@/utils';
import { isValidZipCode, isValidCaZipCode, isValidUsZipCode } from '@/validationRules';
import AddressVerificationModal from '@/components/ApplicantAndWearer/AddressVerificationModal';
import moment from 'moment';
import CrimeErrorModal from '@/components/ApplicantAndWearer/CrimeErrorModal';
import { isValidateLoss, isCrimeValidated } from '@/utils/jewelry-value-rules';
export default {
    name: 'ApplicantAndWearersForm',
    components: {
        CrimeErrorModal,
        AddressVerificationModal,
        FormattedInput,
        CrimeSection,
        ImportantInformationAboutWebsite,
        WearerJewelryItemsForm,
        MailingAddressForm,
        RadioButton,
        DateWithDropdowns,
        SelectInput,
        TextInput,
        Panel,
        Title,
        Checkbox,
        Button,
        ValidationProvider,
        ValidationObserver
    },
    data: () => ({
        genders: constants.genders,
        form: {
            applicant: cloneDeep(classes.applicantForm),
            wearerJewelryItems: [],
            wearerCrime: cloneDeep(classes.wearerCrime),
            agreedTerms: false
        },
        showAllErrors: false,
        valid: {
            firstName: false,
            lastName: false,
            address: false,
            city: false,
            state: false,
            zipCode: false,
            emailAddress: false,
            dateOfBirth: false,
            gender: false,
            mailingAddress: true,
            wearers: false,
            crime: false,
            termsOfService: false
        },
        validZipCodes: {},
        validatingAddresses: false
    }),
    mounted() {
        window.scrollTo(0, 0);
        let updateApplicantAndWearers = false;
        if (this.applicantAndWearersForm) {
            this.form = this.applicantAndWearersForm;
        } else {
            updateApplicantAndWearers = true;
        }

        this.form.applicant.zipCode = this.previewQuoteLocation.zipCode;
        this.form.applicant.city = this.previewQuoteLocation.city;
        this.form.applicant.country = this.previewQuoteLocation.country;
        this.form.applicant.county = this.previewQuoteLocation.county;
        this.form.applicant.state = this.previewQuoteLocation.state;
        this.form.applicant.effectiveDate = this.form.applicant.effectiveDate || moment().format('YYYY-MM-DD');

        if (!this.form.wearerJewelryItems || !this.form.wearerJewelryItems.length) {
            this.form.wearerJewelryItems = this.jewelryItems.map(() => cloneDeep(classes.jewelryItemForm));
            updateApplicantAndWearers = true;
        }
        if (!this.form.wearerCrime) {
            this.form.wearerCrime = cloneDeep(classes.wearerCrime);
            updateApplicantAndWearers = true;
        }
        if (updateApplicantAndWearers) {
            this.setApplicantAndWearers(this.form);
        }
    },
    watch: {
        form: {
            handler: function(val) {
                this.setApplicantAndWearers(val);
            },
            deep: true
        },
        country(val) {
            this.applicantAndWearers.applicant.country = val;
        }
    },
    computed: {
        ...mapState({
            applicantAndWearersForm: state => state.applicantAndWearers
        }),
        ...mapGetters(['previewQuoteLocation', 'jewelryItems', 'applicantAndWearers']),
        country() {
            if (
                this.applicantAndWearers &&
                this.applicantAndWearers.applicant &&
                this.applicantAndWearers.applicant.country
            ) {
                if (
                    this.applicantAndWearers.applicant.zipCode &&
                    isValidCaZipCode(this.applicantAndWearers.applicant.zipCode)
                ) {
                    return 'CA';
                } else if (
                    this.applicantAndWearers.applicant.zipCode &&
                    isValidUsZipCode(this.applicantAndWearers.applicant.zipCode)
                ) {
                    return 'US';
                } else {
                    return this.applicantAndWearers.applicant.country;
                }
            }
            return this.previewQuoteLocation.country;
        },
        stateOptions() {
            const states = constants.states[this.country] || constants.states.US;
            return states.map(state => ({
                text: state.name,
                value: state.abbreviation
            }));
        },
        youngestApplicantAge() {
            const age = new Date();
            age.setFullYear(age.getFullYear() - 18);
            return age.toISOString();
        }
    },
    methods: {
        ...mapMutations(['setApplicantAndWearers', 'setLoadingQuotes']),
        ...mapActions(['loadPreviewQuotes', 'loadRateQuote']),
        isValidZipCode(v) {
            return isValidZipCode(v);
        },
        async verifyAddresses() {
            this.$bvModal.show('addressVerificationModal');
        },
        finishedAddressValidation() {
            const that = this;
            // if zipcode has changed then reload quotes
            this.setLoadingQuotes(true);
            this.loadRateQuote({ update: true })
                .then(function() {
                    that.setLoadingQuotes(false);
                })
                .catch(function() {
                    that.setLoadingQuotes(false);
                });

            this.$router.push('/jewelry-details');
        },

        async validateAddresses() {
            this.validatingAddresses = true;

            this.setApplicantAndWearers(this.form);

            await this.verifyAddresses();

            this.validatingAddresses = false;
        },
        async submit() {
            const success = await this.$refs.form.validate();

            if (Object.keys(this.valid).find(f => !this.valid[f]) || !success) {
                this.showAllErrors = true;
                try {
                    this.$nextTick(() => scrollToError(this.$refs.form));
                } catch (e) {
                    /* Ignore */
                }
                return;
            }
            if (!isCrimeValidated(this.form) || !isValidateLoss(this.form)) {
                this.$refs.crimeModal.show();
                return false;
            }

            await this.validateAddresses();
        },
        back() {
            this.$router.push('/results');
        },
        saveAndFinishLater() {
            this.$eventBus.$emit('save');
        },
        showMailingAddress(val) {
            this.form.applicant.isMailingAddress = val;
            this.valid.mailingAddress = !!val;
        },
        required(v) {
            return !!v;
        },
        setValid(field, value) {
            this.$set(this.valid, field, value);
        }
    }
};
</script>

<style lang="scss" scoped>
.applicant-wearers-form {
    position: relative;
}

.form-padding {
    padding: 6px 12px;
}

.save {
    display: inline-block;
    color: $blue;
    font-weight: 700;
    text-decoration: underline;
    user-select: none;
    cursor: pointer;
}

.back {
    color: $blue;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
}

.mobile-reverse {
    display: flex;
    justify-content: space-between;

    @media (max-width: 599px) {
        flex-direction: column-reverse;
        text-align: center;

        button {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.validation-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
}
</style>
