<template>
    <b-form-group :label-for="uuid" :invalid-feedback="errorMessage" class="font-weight-bold" label-size="sm">
        <template v-slot:label>
            {{ label }}
        </template>
        <b-input-group
            :class="cleaveOptions.options.clazz"
            :prepend="cleaveOptions.prepend"
            class="mb-2 mr-sm-2 mb-sm-0"
        >
            <cleave
                :value="value"
                :options="cleaveOptions.options"
                class="form-control"
                :name="uuid"
                @input="onInput"
                :class="{ 'is-invalid': hasError }"
                :placeholder="placeholder"
            ></cleave>
        </b-input-group>
        <b-form-invalid-feedback :force-show="hasError" class="form-control-error">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </b-form-group>
</template>

<script>
import { v4 } from 'uuid';
import Cleave from 'vue-cleave-component';

const types = {
    PHONE: 'phone',
    CURRENCY: 'currency',
    NUMBER: 'number',
    ZIPCODE: 'zipcode'
};
export default {
    name: 'FormattedInput',
    components: { Cleave },
    props: {
        value: {
            type: [Number, String],
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            required: true
        },
        errorMessage: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            uuid: v4(),
            phoneOptions: {
                numericOnly: true,
                blocks: [0, 3, 3, 4],
                delimiters: ['(', ') ', '-'],
                clazz: 'phone'
            },
            currencyOptions: {
                numeral: true,
                clazz: 'currency'
            },
            numberOptions: {
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                clazz: 'number'
            },
            zipCodeOptions: {
                numericOnly: true,
                blocks: [5],
                clazz: 'zip'
            }
        };
    },
    computed: {
        cleaveOptions() {
            let cleaveOption = {};
            if (this.type === types.PHONE) {
                cleaveOption = {
                    options: this.phoneOptions
                };
            } else if (this.type === types.CURRENCY) {
                cleaveOption = {
                    options: this.currencyOptions,
                    prepend: '$'
                };
            } else if (this.type === types.NUMBER) {
                cleaveOption = {
                    options: this.numberOptions
                };
            } else if (this.type === types.ZIPCODE) {
                cleaveOption = {
                    options: this.zipCodeOptions
                };
            } else return {};
            return cleaveOption;
        },
        hasError() {
            return !!this.errorMessage;
        },
        placeholder() {
            if (this.type === types.PHONE) {
                return '(___)___-____';
            }
            return '';
        }
    },
    methods: {
        onInput(value) {
            this.$emit('input', value);
        }
    }
};
</script>

<style scoped lang="scss">
.form-control {
    border-radius: 0;

    &.is-invalid {
        border-color: #f04033;
        background-color: #ffe1e4;

        &:focus {
            background-color: #ffffff;
        }
    }
}
</style>
