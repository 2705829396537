<template>
    <b-form-row>
        <b-col class="form-padding" cols="12" sm="6">
            <TextInput
                :model="form.address"
                :show-error="showErrors"
                :validator="required"
                name="mailing-applicant-address"
                autocomplete="shipping address-line1"
                :title="`${noPrefix ? '' : 'Mailing'} Address`"
                @inputChanged="val => (form.address = val)"
                @validate="v => setValid('address', v)"
            />
        </b-col>
        <b-col class="form-padding" cols="12" sm="6">
            <TextInput
                :model="form.apartmentSuite"
                name="mailing-applicant-apartment-suite"
                autocomplete="shipping address-line2"
                :title="`${noPrefix ? '' : 'Mailing'} Apartment/Suite`"
                @inputChanged="val => (form.apartmentSuite = val)"
            />
        </b-col>
        <b-col class="form-padding" cols="12" sm="6">
            <TextInput
                :model="form.city"
                :show-error="showErrors"
                :validator="required"
                name="mailing-applicant-city"
                autocomplete="shipping address-level2 locality"
                :title="`${noPrefix ? '' : 'Mailing'} City`"
                @inputChanged="val => (form.city = val)"
                @validate="v => setValid('city', v)"
            />
        </b-col>
        <b-col class="form-padding" cols="12" sm="6">
            <SelectInput
                :model="form.state"
                :show-error="showErrors"
                :validator="required"
                :options="stateOptions"
                name="mailing-applicant-state"
                autocomplete="shipping address-level1 province"
                :title="`${noPrefix ? '' : 'Mailing'} ${country === 'US' ? 'State' : 'Province'}`"
                @inputChanged="val => (form.state = val)"
                @validate="v => setValid('state', v)"
            />
        </b-col>
        <b-col class="form-padding" cols="12">
            <b-col class="pa-0" cols="5" sm="3">
                <TextInput
                    :model="form.zipCode"
                    :show-error="showErrors"
                    :validator="isValidZipCode"
                    name="mailing-applicant-zip-code"
                    autocomplete="shipping postal-code"
                    :title="`${noPrefix ? '' : 'Mailing'} Zip Code`"
                    @inputChanged="val => (form.zipCode = val)"
                    @validate="v => setValid('zipCode', v)"
                />
            </b-col>
        </b-col>
    </b-form-row>
</template>

<script>
import { mapGetters } from 'vuex';
import utils from '@/utils';
import TextInput from '@/components/FormGroups/TextInput';
import SelectInput from '@/components/FormGroups/SelectInput';
import constants from '@/constants';
import { isValidZipCode } from '@/validationRules';

export default {
    name: 'MailingAddressForm',
    components: { SelectInput, TextInput },
    props: {
        form: {
            type: Object,
            required: true,
            validator: ({ address, apartmentSuite, city, state, zipCode }) =>
                utils.exists(address) &&
                utils.exists(apartmentSuite) &&
                utils.exists(city) &&
                utils.exists(state) &&
                utils.exists(zipCode)
        },
        noPrefix: {
            type: Boolean, // Removes 'Mailing' from the title of inputs.
            required: false,
            default: false
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        valid: {
            address: false,
            city: false,
            state: false,
            zipCode: false
        }
    }),
    computed: {
        ...mapGetters(['previewQuoteLocation', 'applicantAndWearers']),
        country() {
            if (
                this.applicantAndWearers &&
                this.applicantAndWearers.applicant &&
                this.applicantAndWearers.applicant.country
            ) {
                return this.applicantAndWearers.applicant.country;
            }
            return this.previewQuoteLocation.country;
        },
        stateOptions() {
            const states = constants.states[this.country] || constants.states.US;
            return states.map(state => ({
                text: state.name,
                value: state.abbreviation
            }));
        },
        isAllValid() {
            return !Object.keys(this.valid).find(f => !this.valid[f]);
        }
    },
    methods: {
        required(val) {
            return !!val;
        },
        isValidZipCode(v) {
            return isValidZipCode(v);
        },
        setValid(field, value) {
            this.valid[field] = value;
            this.$emit('validate', this.isAllValid);
        }
    },
    watch: {
        form: {
            handler: function(val) {
                this.$emit('inputChanged', val);
            },
            deep: true
        }
    }
};
</script>

<style scoped></style>
