<template>
    <div>
        <b-row
            v-for="(item, i) in model"
            :key="i"
            class="d-flex justify-content-between align-items-sm-center relative"
            no-gutters
        >
            <b-col class="relative-top font-weight-bold" cols="0">
                <span>{{ i + 1 }}</span>
            </b-col>
            <b-col cols="3">
                <TextInput
                    :model="item.date"
                    :name="`lost-item-date-${i}`"
                    :title="i === 0 ? 'Date of Loss' : ''"
                    :show-error="showErrors"
                    :validator="required"
                    type="date"
                    @inputChanged="val => (item.date = val)"
                    @validate="v => setValid(i, 'date', v)"
                />
            </b-col>
            <b-col cols="3">
                <SelectInput
                    :model="item.type"
                    :name="`lost-item-type-${i}`"
                    :options="lossItems"
                    :title="i === 0 ? 'Type of Loss' : ''"
                    :show-error="showErrors"
                    :validator="required"
                    @inputChanged="val => (item.type = val)"
                    @validate="v => setValid(i, 'type', v)"
                />
            </b-col>
            <b-col cols="3">
                <TextInput
                    :formatter="priceFormatter"
                    :model="item.amount"
                    :name="`lost-item-amount-${i}`"
                    :show-error="showErrors"
                    :validator="required"
                    max="10000000"
                    min="0"
                    :title="i === 0 ? 'Amount of Loss' : ''"
                    type="number"
                    @inputChanged="val => (item.amount = val.includes('.0') ? val.split('.0')[0] : val)"
                    @validate="v => setValid(i, 'amount', v)"
                />
            </b-col>
            <b-col cols="0" class="relative-top">
                <b-icon
                    :class="{ disabled: model.length === 1 }"
                    class="h5 pointer"
                    icon="x-circle"
                    @click="remove(i)"
                />
            </b-col>
        </b-row>
        <b-col class="add-item" cols="12">
            <span class="pointer no-select" @click="add">
                <b-icon class="h5" icon="plus-circle" />
                Add another loss
            </span>
        </b-col>
    </div>
</template>

<script>
import utils from '@/utils';
import TextInput from '@/components/FormGroups/TextInput';
import constants from '@/constants';
import SelectInput from '@/components/FormGroups/SelectInput';

export default {
    name: 'CrimeSectionLostItems',
    components: { SelectInput, TextInput },
    props: {
        model: {
            type: Array,
            required: true,
            validator: arr =>
                arr.every(({ date, type, amount }) => utils.exists(date) && utils.exists(type) && utils.exists(amount))
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        lossItems: constants.lossItems,
        valid: []
    }),
    computed: {
        isAllValid() {
            return !this.valid.find(item => !!Object.keys(item).find(f => !item[f]));
        }
    },
    mounted() {
        this.valid = this.model.map(() => ({
            date: false,
            type: false,
            amount: false
        }));
    },
    methods: {
        add() {
            this.model.push({
                date: '',
                type: '',
                amount: ''
            });
            this.valid.push({
                date: false,
                type: false,
                amount: false
            });
            this.emitValidate();
        },
        remove(index) {
            if (this.model.length > 1) {
                this.model.splice(index, 1);
                this.valid.splice(index, 1);
                this.emitValidate();
            }
        },
        priceFormatter(value) {
            if (value) {
                return value < 0 ? 0 : value;
            }

            return '0';
        },
        emitValidate() {
            this.$emit('validate', this.isAllValid);
        },
        required(val) {
            return !!val;
        },
        setValid(index, field, value) {
            this.valid[index][field] = value;
            this.emitValidate();
        }
    }
};
</script>

<style lang="scss" scoped>
.relative:first-child {
    .relative-top {
        position: relative;
        top: 13px;

        @media (max-width: 576px) {
            top: 4px;
        }
    }
}

.relative:not(:first-child) {
    .relative-top {
        @media (max-width: 576px) {
            align-items: center;
            display: flex;
        }
    }
}

.add-item {
    color: $blue;
    font-weight: 700;
    margin-top: $margin-under-element;
    user-select: none;

    svg {
        vertical-align: top;
    }

    @media (min-width: 576px) {
        margin-left: 45px;
    }
}
</style>
